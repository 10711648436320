import { createTheme } from '@mui/material/styles'

export default createTheme({
  palette: {
    primary: {
      main: "#10141f"
    },
    secondary: {
      main: "#a3ffae"
    }
  }
})